<template>
  <div>
      <div class="row">
            <div class="col-md-12">
                <div class="mb-2 clearfix">
                    <div class="display-options headder-user-page" id="displayOptions">               
                        <SearchForm v-model="search" :placeholder="placeholderInput"/>
                        <div class="float-md-right d-flex">
                            <btnAdd :message="btnTxt"/>
                        </div>
                    </div>
                </div>
                <p class="rappel">
                    Votre entreprise compte {{users.length}} employé(s)
                </p>
                <div class="separator mb-20"></div>
                <b-overlay :show="showOverlayTable" rounded="sm">
                    <!-- <b-alert variant="info" class="text-center" show v-if="!cites.length">
                        <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                        <span class="h4 d-inline-flex ml-2">{{ $t('data.cite_pas_de_cite_enregistrer') }}</span>
                    </b-alert>  -->
                    <b-row class="layout-wrap">
                        <b-col v-for="(user, i) in users" :key="i" xl="3" lg="4" cols="12" sm="6" class="mb-4">
                            <div class="user-item">
                                <div class="user-img" @click.prevent="showDetails">
                                    <img :src="user.utilisateur.avatar" alt="photo de l'utilisateur">
                                    <div v-if="user.isAdmin" class="isAdmin">
                                        Administrateur
                                    </div>
                                </div>
                                <div class="user-info">
                                    <div class="txt">
                                    <span class="username">
                                            {{ user.utilisateur.prenom }} {{ user.utilisateur.nom }}
                                        </span>
                                        <span class="user-post">
                                            Poste : <b>{{ user.poste }}</b>
                                        </span> 
                                        <span class="user-mail">
                                            Mail : {{ user.utilisateur.email }}
                                        </span> 
                                    </div>
                                    <div class="user-action">
                                        <!-- <span>
                                            Possède <br>
                                            <b>{{ cite.batiments.length }} batiment<b v-show="cite.batiments.length > 1">s</b> </b>
                                        </span> -->
                                        <div class="btns">
                                            <button @click.prevent="userDetails(row.item)" v-b-tooltip.bottom="$t('data.cite_tooltip_details')">
                                                <i class="ik ik-eye"></i>
                                            </button>
                                            <div class="barre"></div>
                                            <butto @click.prevent="editUser(row.item)" v-b-tooltip.bottom="$t('data.cite_tooltip_editer')">
                                                <i class="ik ik-edit-2"></i>
                                            </butto>
                                            <div class="barre"></div>
                                            <button @click.prevent="showMsgBoxTwo(row.item)" v-b-tooltip.bottom="$t('data.cite_tooltip_supprimer')">
                                                <i class="ik ik-trash-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- <paginator hr="top" :offset="offset" :total="cites.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" v-show="items.length > 5" />                    -->
                </b-overlay>
            </div>
        </div>
  </div>
</template>

<script>
import SearchForm from "@/components/parts/SearchForm.vue";
const php  = require ( 'phpjs' ) ; 
export default {
    components: {
        SearchForm,
    },
    data: () =>({
        placeholderInput:'Rechercher un utilisateur',
        btnTxt:'Ajouter un nouvel utilisateur',
        users:[],
        trueUsers:[],
        userSet:null,
        search: null,
        action:'add',
        showOverlayTable : true
    }),
    watch: {
        search(value) {
            if (!php.empty(value)) {
                this.users = this.trueUsers.filter(elt => elt.utilisateur.nom.toLowerCase().includes(value.toLowerCase()))
            }
            else {
                this.users = this.trueUsers
            }
        }
    },
    methods:{
        getUsers() {
            this.showOverlayTable = true;
            axios.get('entreprises/utilisateurs').then(response => response.result || []).then(result => {
                this.users = result
                console.log('users good',this.users )
                this.showOverlayTable = false
            })
         },
          editionSuccessfull(){
              this.$bvModal.hide('userForm')
              this.resetData();
              this.getUsers ();
          },
          addedUser(user){        
              this.$bvModal.hide('userForm')
              this.resetData();
              this.getUsers ();
          },
          userDetails(user){

              console.log('user', user);
              this.$router.push({path: '/users/'+user.idUtilisateur})
              //this.$router.push({ name: 'details-user', params: { id : user.idUtilisateur } })
          },
          editUser(user){
              console.log('user to edit', user)
              this.userSet=user;
              this.action="edit"
              this.$bvModal.show('userForm')
          },
          deleteUser(user){
              axios.delete(`entreprises/utilisateurs/${user.idUtilisateur}`).then(response => {
                    if (!response.success) {
                        return App.notifyError(response.message)
                    }
                    this.getUsers();
                    return App.notifySuccess(response.message)
                }).catch(error => {
                    App.notifyError(error.message)
                })

          },
         resetData(){
             this.userSet=null;this.action="add"
         },
         showMsgBoxTwo(user) {
        this.boxTwo = ''
        this.$bvModal.msgBoxConfirm('etes-vous, sur de voulir retirer cet utilisateur de cette entreprise ?', {
          title: 'SUPPRESION !!!',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'OUI',
          cancelTitle: 'NON',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
            })
            .then(value => {
                console.log('value', value);
                this.boxTwo = value
                if(value){
                    this.deleteUser(user);
                }
                
            })
            .catch(err => {
                // An error occurred
            })
        }
    },
    mounted(){
         this.getUsers();

    },
}
</script>

<style>
    p.rappel{
        font-size: 1.2rem;
        margin-top:40px;
        font-weight:bold;
        color:#191c22
    }
    .headder-user-page{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
    }
    .user-item{
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        width: 18rem;
        height: 20rem;
        background: white;
        border-radius: 8px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
    }
    .user-item .user-img{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48%;
        border-radius: 8px 8px 0 0;
    }
    .user-img .isAdmin{
        padding: 8px 15px;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        width: auto;
        border-radius: 8px 0 0 8px;
        color: white;
        font-size: .8rem;
        font-weight: bold;
    }
    .user-item .user-img img{
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        object-fit: fill;
        background: coral;
    }
    .user-item .user-info{
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 52%;
        width: 100%;
    }
    .user-info .txt{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        width: 100%;
    }
    .txt .username{
        margin-bottom: .5rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: #191c22;
    }
    .txt .user-post,.txt .user-mail{
        font-size: .9rem;
        color: #5c5c5c;
    }
    .user-info .user-action{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    .user-action span{
        font-size: .9rem;
        color: #5c5c5c;
    }
    .user-action .btns{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 40px;
    }
    .user-action .barre{
        margin: 0 5px;
        height: 80%;
        width: 2px;
        background: #efefef;

    }
    .user-action button{
        background: transparent;
        border: none;
    }
    .user-action button i{
        font-size: 1rem;
    }
</style>